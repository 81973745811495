@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  overflow-x: hidden;
}

body {
  font-family: var(--title-font);
  font-weight: 400;
  color: var(--body-color);
  line-height: 26px;
  max-width: 100%;
}

@media screen and (min-width: 1950px) {
  
  #root {
    display: flex;
    justify-content: center;
  }
}

:root {
  --theme-color1-sl:#234396;
  --theme-color2-sl: #1F56C0;
  --theme-color3-sl: #1F81DC;

  --theme-color: #1F56C0;
  --theme-color2: #df0707;
  --title-color: #141D38;
  --body-color: #737887;
  --white-color: #fff;
  --smoke-color: #F5F5F5;
  --smoke-color2: #E2E8FA;
  --smoke-color3: #EFF1F9;
  --black-color: #000000;
  --black-color2: #080E1C;
  --gray-color: #bdbdbd;
  --white-color: #ffffff;
  --light-color: #72849B;
  --yellow-color: #FFB539;
  --success-color: #28a745;
  --error-color: #dc3545;
  --th-border-color: #E0E0E0;
  --title-font: 'Barlow', sans-serif;
  --body-font: 'Roboto', sans-serif;
  --icon-font: "Font Awesome 6 Pro";
  --main-container: 1220px;
  --container-gutters: 24px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 60px;
  --ripple-ani-duration: 5s;
}