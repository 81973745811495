.content_page_wrapper {
    width: 100%;
}

.content_page_wrapper .content_page_banner_wrapper {
    width: 100%;
    background: url('../images/content_banner.jpg');
    min-height: 40vh;
    /* padding-top: 100px; */
    margin-top: 96px;
}

.content_page_wrapper .ahmedabad_banner {
    background: url('../images/ahmedabad.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    min-height: 50vh;
}

.content_page_wrapper .hyderabad_banner {
    background: url('../images/hyderabad.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    min-height: 50vh;
}

.content_page_wrapper .gurgaon_banner {
    background: url('../images/gurgaon.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    min-height: 50vh;
}

.content_page_wrapper .noida_banner {
    background: url('../images/noida.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    min-height: 50vh;
}

.content_page_wrapper .faridabad_banner {
    background: url('../images/faridabad.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    min-height: 50vh;
}

.content_page_wrapper .greaternoida_banner {
    background: url('../images/greaternoida.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    min-height: 50vh;
}

.content_page_wrapper .ghaziabad_banner {
    background: url('../images/ghaziabad.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    min-height: 50vh;
}

.content_page_wrapper .bangalore_banner {
    background: url('../images/banglore.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    min-height: 50vh;
}

.content_page_wrapper .mumbai_banner {
    background: url('../images/mumbai.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    min-height: 50vh;
}

.content_page_wrapper .delhi_banner {
    background: url('../images/delhi.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    min-height: 50vh;
}


.content_page_wrapper .service_page_banner_wrapper_overlay {
    width: 100%;
    display: flex;
    padding-top: 120px;
    padding-bottom: 120px;
    justify-content: center;
    align-items: center;
}
.content_page_wrapper .content_page_banner_wrapper_overlay {
    
    min-height: 50vh;
    padding: 196px 0 43px;
    background-size: cover;
    width: 100%;
    float: left;
    text-align: center;
    margin-top: -121px;
}

.content_page_wrapper .service_page_banner_wrapper_overlay h2 {
    color: var(--white-color);
    font-size: var(--title-font-size);
    font-family: var(--body-font);
}

.content_page_wrapper .content_page_banner_wrapper_overlay h2 {
    color: var(--white-color);
    font-size: var(--title-font-size);
    font-family: var(--body-font);
}

.content_page_wrapper .text_content_wrapper {
    width: 100%;
    padding: 20px 10px;
    background: #eff2f6;
}

.content_page_wrapper .text_content_wrapper .text_content {
    width: 70%;
    margin: 0px auto;
    padding: 20px 50px;
    background: #fff;
}

.content_page_wrapper .text_content_wrapper .text_content .content_banner {
    width: 100%;
    display: flex;
    justify-content: center;
}

.content_page_wrapper .text_content_wrapper .text_content .content_banner img {
    max-width: 90%;
    border-radius: 10px;
}

.content_page_wrapper .text_content_wrapper .text_content .page_title {
    font-family: var(--body-font);
    color: var(--title-color);
    width: 100%;
    text-align: center;
    font-size: var(--title-font-size);
    line-height: 3rem;
}

.content_page_wrapper .text_content_wrapper .text_content h3 {
    font-family: var(--body-font);
    color: var(--title-color);
    width: 100%;
    font-size: var(--subheading-font-size);
    margin-top: 20px;
}

.content_page_wrapper .text_content_wrapper .text_content p {
    font-family: var(--body-font);
    line-height: 1.78;
    color: var(--body-color);
    font-size: var(--para-font-size);
}

.content_page_wrapper .text_content_wrapper .text_content .button_container a {
    background: var(--theme-color);
    color: white;
    padding: 8px 12px;
    border: 1px solid var(--theme-color);
    font-size: var(--para-font-size);
    font-weight: 600;
}

.content_page_wrapper .text_content_wrapper .text_content .content_row {
    width: 100%;
    min-height: fit-content;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-center;
}


.content_page_wrapper .text_content_wrapper .text_content .content_row .content_item {
    max-width: 45%;
}

.content_page_wrapper .text_content_wrapper .text_content .content_row .content_item h3,
.content_page_wrapper .text_content_wrapper .text_content .content_row .content_item h2 {
    font-family: var(--body-font);
    color: var(--title-color);
    font-size: var(--subtitle-font-size);
}

.content_page_wrapper .text_content_wrapper .text_content .content_row .content_item p {
    font-family: var(--body-font);
    color: var(--title-color);
}

.content_page_wrapper .text_content_wrapper .text_content .content_row .content_item .light_color {
    color: var(--light-color);
}

.content_page_wrapper .text_content_wrapper .text_content .content_row .content_item span {
    font-size: var(--links-font-size);
    font-family: var(--body-font);
    color: var(--title-color);
}

.content_page_wrapper .text_content_wrapper .text_content h4 {
    font-family: var(--body-font);
    color: var(--title-color);
    font-size: 20px;
    margin-top: 20px;
}

.content_page_wrapper .text_content_wrapper .text_content .content_row .content_item ul {
    list-style-type: decimal;
    margin-left: 20px;
}

.content_page_wrapper .text_content_wrapper .text_content .content_row .content_item ul .tc_li {
    margin-top: 10px;
}

.content_page_wrapper .text_content_wrapper .text_content .locations_list .location_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}

.content_page_wrapper .text_content_wrapper .text_content .content_list {
    margin-left: 20px;
}

.content_page_wrapper .text_content_wrapper .text_content .content_list li {
    margin-top: 5px;
    font-family: var(--body-font);
}

@media screen and (max-width: 800px) {
    .content_page_wrapper .text_content_wrapper .text_content {
        width: 90%;
    }

    .content_page_wrapper .text_content_wrapper .text_content .content_banner img {
        width: 90%;
        min-width: 300px;
    }

}

@media screen and (max-width: 630px) {
    .content_page_wrapper .text_content_wrapper .text_content .content_row {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .content_page_wrapper .text_content_wrapper .text_content .content_row .content_item {
        max-width: 100%;
        margin-top: 20px;
    }

}

@media screen and (max-width: 420px) {
    .content_page_wrapper .text_content_wrapper .text_content {
        padding: 20px;
    }
}