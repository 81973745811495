.loan-history {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 65px;
  }
  
  .lead-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 250px;
    transition: transform 0.2s;
    color: #737887;
  }
  
  .lead-card:hover {
    transform: scale(1.05);
  }
  
  .lead-card h3 {
    margin: 0 0 10px;
    font-size: 1.25rem;
  }
  
  .lead-card p {
    margin: 5px 0;
  }
  

  .loan-history-card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin: 35px 55px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
  }
  
  .loan-history-card h2 {
    margin: 0 0 10px;
    font-size: 24px;
    color: #333;
  }
  
  .loan-history-card p {
    margin: 5px 0;
    font-size: 16px;
    color: #666;
  }
  

  .collection-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns of equal width */
    gap: 10px; /* Space between grid items */
    margin: 20px 0; /* Margin for spacing */
  }
  
  .collection-card {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for better appearance */
  }
  
  