/* top bar css  */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

/* Import Material Icons */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

:root {
    --title-font-size: 2rem;
    --logo-font-size: 1.5rem;
    --banner-font-size: 3rem;
    --para-font-size: 1rem;
    --subtitle-font-size: 1.25rem;
    --welcome-msg-font-size: 1.25rem;
    --links-font-size: 0.9rem;
    --icon-font-size: 2.6rem;
    --service-title-font-size: 1.5rem;
    --input-font-size: 1rem;
    --service-card-number-font-size: 5rem;
    --label-font-size: 0.92rem;
    --subheading-font-size: 1.5rem;
}
.maxw-1350{
    max-width: 1350px;
    margin-left: auto;
    margin-right: auto;
}


.top_bar_wrapper {
    width: 100%;
    padding: 10px 16px;
    background: var(--theme-color);
}

.top_bar_wrapper .top_bar_container {
    padding: 10px 16px;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
}

.top_bar_wrapper .top_bar_container .contact_details {
    color: var(--white-color)
}

.top_bar_wrapper .top_bar_container .contact_details .icon {
    margin-right: 5px;
    margin-bottom: -2px;
}

.float_content {
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent wrapping */
    width: 100%; /* Full width */
    position: relative; /* For positioning */
    background-color: #ff4848;
    
        text-align: center;

        float: left;
        padding: 8px 0;
        position: fixed;
        z-index: 26;
        bottom: -20px;
    }
        

.marquee {
    display: inline-block; /* Inline block for width */
    animation: marquee 50s linear infinite;
    font-size: 22px; /* Adjust duration as needed */
    color: #fff;
    margin-bottom: 16px;
   
}

@keyframes marquee {
    0% {
        transform: translateX(100%); /* Start off-screen on the right */
    }
    100% {
        transform: translateX(-100%); /* End off-screen on the left */
    }
}



/********************************************* header CSS ******************************** */
.header_wrapper {
    width: 100%;
    padding: 26px 16px;
    position: fixed; /* Make the header fixed at the top */
    top: 0;
    left: 0;
    z-index: 9;
    background-color: black;
    background-image: url("../images/footer_bg_2.jpg");
    background-size: cover;
}

.header_wrapper nav {
    width: 98%;
    margin: 0 auto;
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: reltive;
}

.header_wrapper nav .burger {
    position: absolute;
    top: 25px;
    right: 5px;
    display: none;
    cursor: pointer;
}

.header_wrapper nav .logo_wrapper img {
    max-width: 150px;
    margin-left: 20px;
}

.header_wrapper nav .nav_list {
    gap: 30px;
    display: flex;
    align-items: center;
}

.header_wrapper nav .nav_list li {
    list-style: none;
}

.header_wrapper nav .nav_list li a,
.location_button {
    color: white;
    font-weight: 600;
    font-size: var(--links-font-size);
    font-family: var(--title-font);
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.header_wrapper nav .nav_list li .submenu {
    background: black;
    position: absolute;
    z-index: 2;
    padding-bottom: 10px;
    border-radius: 4px;
}

.header_wrapper nav .nav_list li .submenu li {
    list-style: none;
    margin-top: 5px;
    padding: 2px 20px;
}

.header_wrapper nav .nav_list li .submenu li:nth-child(1) {
    padding-top: 10px;
}

.header_wrapper nav .nav_list li .submenu li a {
    text-transform: none;
}

.header_wrapper nav .nav_list li .submenu li:nth-child(1) {
    margin-top: 0px;
}

.header_wrapper nav .nav_list li .location_button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    padding: 0px;
}

.header_wrapper nav .nav_list li .location_button .caret_icon {
    margin-left: 5px;
}

.header_wrapper .nav_list li .apply_now_button_link {
    background: var(--theme-color);
    color: white;
    padding: 7px 15px;
    border: 1px solid var(--theme-color);
    font-size: var(--links-font-size);
    font-weight: 2000;
    text-transform: none;
    display: flex;
    align-self: center;
    height: 42.4px;
    font-size: 16.4px;
    font-family: Arial;
    margin-left: 20px;
    border-radius: 8px;
}

.header_wrapper .nav_list li .repay_loan_button {
    margin-left: 0px;
}

.header_wrapper .nav_list li .apply_now_button_link:hover {
    color: var(--theme-color);
    background: transparent;
    border: 1px solid var(--theme-color);
}

.header_wrapper nav .nav_list li a:hover {
    color: var(--theme-color);
}

.header_wrapper nav .burger .line {
    width: 28px;
    background-color: white;
    height: 4px;
    margin: 3px 3px;
}

.page_wrapper {
    width: 100%;
    top: 96px;
    position: relative;
    z-index: 1;

}

/***************************** Banner CSS ****************************  */

  .banner_wrapper {
    width: 100%;
    max-height: 600px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .banner_wrapper img {
    max-width: 100%;
    display: block;
    object-fit: cover; /* Ensures the image covers the container */
    transition: opacity 1s ease-in-out;
  }
  
  .control_btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    z-index: 1000;
    font-size: 20px; /* Adjust based on your design */
  }
  
  .control_btn.prev {
    left: 10px;
  }
  
  .control_btn.next {
    right: 10px;
  }
/********************************************* About Us CSS ******************************** */

.about_us {
    width: 98%;
    padding: 80px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about_us .about_us_img {
    position: relative;
    width: 50%;
}

.about_us .about_us_img .underlay_img {
    position: relative;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
}

.about_us .about_us_img .overlay_img {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
}

.about_us .about_us_img .overlay_img:hover {
    will-change: transform;
    transform: perspective(1000px) rotateX(0deg) rotateY(10deg);
    cursor: pointer;
}

.about_us .about_us_content {
    margin-left: 20px;
    width: 50%;
    /* margin-bottom: 169px; */
}

.about_us .about_us_content .title_section {
    margin-bottom: 20px;
}

.about_us .about_us_content .title_section .sub_title {
    color: var(--theme-color);
    font-size: var(--subtitle-font-size);
    font-weight: 600;
}

.about_us .about_us_content .title_section h2 {
    font-family: var(--body-font);
    color: var(--title-color);
    font-weight: 700;
    font-size: var(--title-font-size);
    line-height: 1.3;
}

.about_us .about_us_content .title_section h2 span {
    color: var(--theme-color);
}

.about_us .about_us_content .about_para p {
    font-family: var(--body-font);
    color: var(--body-color);
    line-height: 1.75;
    /* max-width: 20%; */
}

.btn_container a,
button {
    background: var(--theme-color);
    color: white;
    padding: 8px 12px;
    border: 1px solid var(--theme-color);
    font-size: var(--links-font-size);
    font-weight: 600;

    &:hover {
        color: var(--theme-color);
        background: transparent;
        cursor: pointer;
        border: 1px solid var(--theme-color);
    }
}






/********************************************* Services Section  CSS ******************************** */

.services_section {
    width: 100%;
    /* background: url('../images/section_bg.jpg'); */
    min-height: 60vh;
    /* background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    /* padding: 50px 0px; */
    position: relative;
}


.services_section .info_content {
    width: 95%;
    margin: 0 auto;
    padding: 0px;
}

.services_section .title_section .sub_title {
    color: var(--theme-color);
    font-size: var(--subtitle-font-size);
    font-weight: 600;
}

.services_section .title_section h2 {
    font-family: var(--body-font);
    color: var(--white-color);
    font-weight: 700;
    font-size: var(--title-font-size);
    line-height: 1.3;
    max-width: 80%;
}

.services_section .title_section h2 span {
    color: var(--theme-color);
}

.services_section .services_card_container {
    display: flex;
    width: 100%;
    gap: 50px;
    justify-content: space-evenly;
    /* position: absolute;
    top: 65%; */
}

.services_section .services_card_container .services_card {
    max-width: 300px;
    position: relative;
    box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
    background-color: var(--white-color);
    border-radius: 10px;
    text-align: center;
    padding: 24px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    min-height: 310px;
}

.services_section .services_card_container .services_card .services_icon_container {
    height: 80px;
    width: 80px;
    /* background-color: var(--theme-color); */
    border-radius: 50%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    position: relative;
    z-index: 1;
}

.services_section .services_card_container .services_card .services_icon_container::before,
.services_section .services_card_container .services_card .services_icon_container .services_icon_container::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: var(--theme-color);
    z-index: -2;
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    -webkit-animation-duration: var(--ripple-ani-duration);
    animation-duration: var(--ripple-ani-duration);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: ripple;
    animation-name: ripple;
}

@-webkit-keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }

    30% {
        opacity: 0.4
    }

    100% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
        opacity: 0
    }
}

@keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }

    30% {
        opacity: 0.4
    }

    100% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
        opacity: 0
    }
}


.block {
    display: block;
}

.error {
    display: inline-block;
    color: red !important;
    font-size: 14px;
    font-weight: 600;
    min-width: 250px;
}

.absolute {
    position: absolute;
    top: 60px;
    left: 0px;
}

.services_section .services_card_container .services_card .services_icon_container .service_icon {
    position: absolute;
    z-index: 5;
    font-size: var(--icon-font-size);
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    color: white;
}

.services_section .services_card_container .services_card .title {
    margin-top: 30px;
}

.services_section .services_card_container .services_card .title h4 {
    font-size: var(--service-title-font-size);
    line-height: 1.417;
    font-weight: 700;
    font-family: var(--title-font);
    color: var(--title-color);
}

.services_section .services_card_container .services_card .bg_shape {
    position: absolute;
    bottom: -250px;
    left: 0;
    width: 100%;
    opacity: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    pointer-events: none;
    visibility: hidden;
    height: 100%;
}

.services_section .services_card_container .services_card .bg_shape .service_icon {
    font-size: var(--icon-font-size);
}

.services_card_container .services_card:hover .services_icon_container .service_icon {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.services_card_container .services_card:hover .bg_shape {
    opacity: 1;
    visibility: visible;
    bottom: 0px;
}

.services_card_container .services_card:hover .services_icon_container:before,
.services_card_container .services_card:hover .services_icon_container:after {
    background-color: var(--white-color)
}


.services_card_container .services_card:hover .services_icon_container {
    background-color: var(--white-color);
}

.services_card_container .services_card:hover .title h4 {
    color: var(--white-color);
}


.services_section .services_card_container .services_card .card_content p {
    font-family: var(--body-font);
    line-height: 1.75;
}

.services_section .services_card_container .services_card:hover {
    cursor: pointer;
    background-color: var(--theme-color);
    color: var(--white-color);
}

.services_section .services_card_container .services_card:hover .service_icon {
    color: var(--theme-color);
}

.services_section .services_card_container .services_card:hover .btn_container a {
    color: var(--theme-color);
    background: var(--white-color);
    border: 1px solid var(--white-color);
}

/* get started section css  */

.get_started_section {
    width: 100%;
    padding: 30px;
}

.get_started_section .get_started_row {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    padding: 40px 0px;
    margin: 20px auto 50px auto;
}

.get_started_section .get_started_row .image_section {
    position: relative;
    border-radius: 50px;
}

.get_started_section .get_started_row .image_section .underlay_img {
    position: relative;
}

.get_started_section .get_started_row .image_section .overlay_img {
    position: absolute;
    left: 20px;
    top: 50px;
    max-width: 270px;
}

.get_started_section .get_started_row .steps_section {
    margin-top: 40px;
}

.get_started_section .get_started_row .steps_section .app_download_button_container .app_download_icon {
    font-size: 30px;
}

.get_started_section .get_started_row .steps_section .app_download_button_container .button_wrapper a {
    display: inline-block;
    margin-right: 10px;
    background: var(--theme-color);
    color: white;
    padding: 4px 12px;
    border: 1px solid var(--theme-color);
    font-size: var(--links-font-size);
    font-weight: 600;
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34.4px;
    font-size: 14.4px;
    font-family: Arial;
}

.get_started_section .get_started_row .steps_section h3 {
    color: var(--title-color);
    font-size: 24px;
}

.get_started_section .get_started_row .steps_section .step_item {
    padding-bottom: 30px;
}

.get_started_section .get_started_row .steps_section .step_item:not(.last_item) {
    position: relative;
}

.get_started_section .get_started_row .steps_section .step_item:not(.last_item):before {
    content: '';
    width: 1px;
    top: 0;
    bottom: 0;
    position: absolute;
    left: 25px;
    background: var(--theme-color);
}


.get_started_section .get_started_row .steps_section .step_item .step_icon_container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    background: white;
}

.get_started_section .get_started_row .steps_section .step_item .icon_active {
    background: var(--theme-color);
    color: white;
}

.get_started_section .get_started_row .steps_section .step_item .step_icon_container:hover {
    cursor: pointer;
}

.get_started_section .get_started_row .steps_section .step_item .step_icon_container .step_icon {
    color: var(--theme-color);
    font-size: 30px;
}


.get_started_section .get_started_row .steps_section .step_item .icon_active .step_icon {
    color: white;
}

.get_started_section .get_started_row .steps_section .step_item .step_content {
    margin-left: 20px;
}

.get_started_section .get_started_row .steps_section .step_item .step_content {
    padding: 10px 20px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    --webkit-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .2);
    transition: All 600ms ease;
    -webkit-transition: All 600ms ease;
    background-color: #f5f5f5;
    max-width: 350px;
}

.get_started_section .get_started_row .steps_section .step_active .step_content {
    border: 1px solid var(--theme-color);
}

.get_started_section .get_started_row .steps_section .step_item .step_content .content_title {
    color: var(--title-color);
    font-size: 22px;
    margin-bottom: 5px;
}



/********************************************* Gateway Features section ******************************** */

.features_section {
    width: 100%;
    min-height: 70vh;
    /* background: var(--theme-color3-sl); */
    background-position: center;
    background-size: cover;
    padding-top: 60px;
    padding-bottom: 30px;
}

.features_section .features_section_content {
    width: 98%;
    padding: 0px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title_section .sub_title {
    color: var(--theme-color);
    font-size: var(--subtitle-font-size);
    font-weight: 600;
}

.title_section h2 {
    font-family: var(--body-font);
    color: var(--title-color);
    font-weight: 700;
    font-size: var(--title-font-size);
    line-height: 1.3;
}

.title_section h2 span {
    color: var(--theme-color);
}

.features_section .features_section_content .features_section_content_description p {
    font-family: var(--body-font);
    line-height: 1.78;
    color: var(--body-color);
    max-width: 80%;
    font-size: var(--para-font-size);
}

.features_section .features_section_content img:hover {
    will-change: transform;
    transform: rotateX(10px) rotateY(10px);
}

.features_section .features_section_content .features_image img {
    margin-top: 50px;
}

/********************************************* statistics section CSS ******************************** */
.number_statistics {
    width: 100%;
    background: url('../images//number_stats_bg.png');
    background-color: var(--theme-color);
}

.number_statistics .number_row {
    width: 98%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 15px 80px 10px;
}

.number_statistics .number_row .number_statistics_item {
    display: flex;
    gap: 30px;
    align-items: center;
}

.number_statistics .number_row .number_statistics_item .number_statistics_icon {
    width: 112px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    position: relative;
    padding-left: 15px;
    margin-bottom: 2px;
    z-index: 2;
}

.number_statistics .number_row .number_statistics_item .number_statistics_icon::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--black-color2);
    -webkit-clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
    clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
    z-index: -2;
}

.number_statistics .number_row .number_statistics_item .number_statistics_icon::after {
    background-color: var(--white-color);
    top: 2px;
    left: 10px;
    z-index: -1;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    /* background-color: var(--black-color2); */
    -webkit-clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
    clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
}

.number_statistics .number_row .number_statistics_item:hover {
    cursor: pointer;
}

.number_statistics .number_row .number_statistics_item:hover .number_statistics_icon img {
    transition: all 0.3s ease-in-out;
}

.number_statistics .number_row .number_statistics_item:hover .number_statistics_icon img {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.number_statistics .number_row .number_statistics_item .number_statistics_content h3 {
    font-size: var(--title-font-size);
    color: var(--white-color);
    font-weight: 900;
    margin-bottom: 14px;
}

.number_statistics .number_row .number_statistics_item .number_statistics_content p {
    font-weight: 500;
    color: var(--white-color);
    display: block;
}

/* ************************** How it works section  ********************************/

.general_working {
    width: 100%;
    min-height: 50vh;
    background: url('../images//section_bg_2.jpg');
    background-position: center;
    background-size: cover;
    padding: 40px 10px 100px 10px;
}

.general_working .title_section {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}


.general_working .title_section .sub_title {
    color: var(--theme-color);
    font-size: var(--subtitle-font-size);
    font-weight: 600;
    width: 100%;
}

.general_working .title_section h2 {
    font-family: var(--body-font);
    color: var(--title-color);
    font-weight: 700;
    font-size: var(--title-font-size);
    line-height: 1.3;
}

.general_working .process_card_area {
    position: relative;
}

.general_working .process_card_area .process_line {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    display: none;
    text-align: center;
}

.general_working .process_card_area .process_row {
    width: 99%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.general_working .process_card_area .process_row .process_card {
    position: relative;
    box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    background-color: var(--white-color);
    text-align: center;
    padding: 30px 20px;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;

}

.general_working .process_card_area .process_row .process_card h2 {
    color: var(--title-color);
    font-weight: 500;
    font-size: var(--subtitle-font-size);
}

.general_working .process_card_area .process_row .process_card .process_card_number {
    height: 60px;
    width: 60px;
    line-height: 60px;
    background-color: var(--theme-color);
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: -30px;
    left: -30px;
    z-index: 2;
    font-size: var(--title-font-size);
    font-weight: 700;
    color: var(--white-color);
}

.general_working .process_card_area .process_row .process_card .process_card_number::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: var(--theme-color);
    z-index: -1;
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    -webkit-animation-duration: var(--ripple-ani-duration);
    animation-duration: var(--ripple-ani-duration);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: ripple;
    animation-name: ripple;
}

.general_working .process_card_area .process_row .process_card .process_card_number::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: var(--theme-color);
    z-index: -1;
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: var(--ripple-ani-duration);
    animation-duration: var(--ripple-ani-duration);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: ripple;
    animation-name: ripple;
}

.general_working .process_card_area .process_row .process_card .process_icon {
    color: var(--theme-color);
    font-size: var(--icon-font-size);
}

/******** advantages section css *************/
.advantages_wrapper {
    width: 100%;
}

.advantages_wrapper .title_section p {
    color: var(--title-color);
    font-size: 16px;
}

.advantages_section {
    width: 100%;
    padding: 40px 10px;
    position: relative;
}

.advantages_section:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    padding: 20px;
    top: 80px;
    bottom: 80px;
    z-index: 0;
    border-radius: 10px;
    /* background: var(--theme-color); */
}

.advantages_section .container {
    position: relative;
    z-index: 1;
}

@media screen and (min-width: 1000px) {
    .advantages_section .container {
        max-width: 1300px;
        margin: 0 auto;
    }
}


.advantages_section .advantages_section_row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.advantages_section .advantages_section_row:nth-child(2) {
    margin-top: 30px;
}

.advantages_section .advantages_section_row .advantage_section_card {
    min-width: 200px;
    background: white;
    padding: 20px;
    color: black;
    border-radius: 8px;
    max-width: 400px;
    box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.1);
}

.advantages_section .advantages_section_row .advantage_section_card .advantage_section_icon_container .advantage_icon {
    font-size:  var(--icon-font-size);
    color: white;
}

.advantages_section .advantages_section_row .advantage_section_card .advantage_section_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--theme-color);
    border-radius: 10px;
}

@media screen and  (min-width: 1000px) {
    .advantages_section .advantages_section_row .advantage_section_card .advantage_section_icon_container {
        width: 90px;
        height: 90px;
    }
}

.advantages_section .advantages_section_row .advantage_section_card  h3 {
    margin-top: 10px;
}


/* ***************************Footer CSS ***************************** */
.footer_wrapper {
    min-height: 30vh;
    margin-top: 100px;
    /* background: url('../images/footer_bg_2.jpg');
    background-color: var(--black-color2);
    background-size: cover; */
}

.footer_wrapper .footer_row {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin: 0 auto;
}

.footer_wrapper .footer_row .footer_tab {
    text-align: left;
    padding-top: 50px;
}

.footer_wrapper .footer_row .footer_tab .tab_title {
    max-width: 270px;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0px 0px 17px 0px;
    position: relative;
    font-size: var(--subtitle-font-size);
    margin-bottom: 35px;
}

.footer_wrapper .footer_row .footer_tab .tab_title::before {
    position: absolute;
    content: "";
    width: 100px;
    height: 2px;
    background: var(--theme-color);
    left: 0px;
    bottom: 0px;
}


.footer_wrapper .footer_row .footer_tab .tab_title::after {
    position: absolute;
    left: 96px;
    bottom: -5px;
    content: "";
    background-color: var(--white-color);
    border: 2px solid var(--theme-color);
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.footer_wrapper .footer_row .footer_tab p {
    color: #8E939D;
    line-height: 1.75;
    font-family: var(--body-font);
    max-width: 400px;
}

.footer_wrapper .footer_row .footer_tab li {
    list-style: none;
}

.footer_wrapper .footer_row .footer_tab li a {
    font-size: var(--links-font-size);
    font-weight: 400;
    font-family: var(--body-font);
    display: block;
    color: black;
    display: flex;
    align-items: center;
    line-height: 47px;
}

.footer_wrapper .footer_row .footer_tab li a .footer_icon {
    margin-right: 10px;
}

.footer_wrapper .footer_row .footer_tab .call_info {
    line-height: 47px;
}

.footer_wrapper .copyright_section {
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--theme-color);
    padding: 10px 0px;
    margin-bottom: 18px;
}

.footer_wrapper .copyright_section p {
    font-weight: 500;
    font-size: var(--para-font-size);
    font-family: var(--body-font);
}

.footer_dropdown_container {
    width: 100%;
    border-top: 1px solid white;
    margin-top: 50px;
}

.footer_dropdown_container .footer_dropdown {
    max-width: 96%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 20px 0px 0px 0px;
}

.footer_dropdown_container .footer_dropdown .dropdown_item {
    position: relative;
    min-width: fit-content;
}

.footer_dropdown_container .footer_dropdown .dropdown_item_title .dropdown_title {
    font-weight: 600;
    padding: 5px 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: fit-content;
}

.footer_dropdown_container .footer_dropdown .dropdown_item_title .dropdown_title:hover {
    cursor: pointer;
}

.footer_dropdown_container .footer_dropdown .dropdown_item_title .dropdown_title .dropdown_icon {
    margin-left: 4px;
    margin-bottom: -3px;
}

.footer_dropdown_container .submenu {
    max-width: 96%;
    margin: 0 auto;
    justify-content: flex-start;
    justify-content: flex-start;

}

.footer_dropdown_container .submenu .submenu_list {
    list-style: none;
    display: flex;
    gap: 15px;
}

.footer_dropdown_container .submenu .submenu_list li {
    padding: 5px 12px;
    min-width: fit-content;
}

.footer_dropdown_container .submenu .submenu_list li a {
    color: #e5e0e0;
    font-size: 14px;
}

.footer_dropdown_container .dropdown_active {
    background-color: #1a294c69;
}

.page_banner_wrapper {
    width: 100%;
    min-height: 34vh;
    background: url('../images/banner_bg.jpg');
    background-size: cover;
    background-position: center;
}

.repay_loan_banner {
    background: url('../images/repay_loan_banner.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.page_banner_wrapper .page_banner_wrapper_overlay {
    min-height: 50vh;
    /* padding: 196px 0 43px; */
    background-size: cover;
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 45px;
    
}

.page_banner_wrapper .repay_loan_banner_overlay {
    /* background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)); */
    width: 100%;
    min-height: 27vh;
}

.page_banner_wrapper .page_banner_wrapper_overlay h2 {
    color: var(--white-color);
    font-size: var(--title-font-size);
    font-family: var(--body-font);
}

.page_wrapper .repay_loan_wrapper {
    width: 100%;
    padding: 50px 10px;
}

.page_wrapper .repay_loan_section {
    width: 80%;
    margin: 0 auto;
}

.page_wrapper .repay_loan_section .pan_number .note {
    font-size: var(--para-font-size);
    color: red;
    font-weight: 500;
}

.page_wrapper .repay_loan_section .pan_number span {
    display: inline-block;
    margin-bottom: 5px;
}

.page_wrapper .repay_loan_section .pan_number input {
    color: var(--title-color);
    font-size: var(--input-font-size);
    padding: 8px 12px;
    background: transparent;
    border: none;
    border-bottom: 2px solid #adadad;
    cursor: pointer;
    min-width: 250px;
    width: 45%;
}

.page_wrapper .repay_loan_section .pan_number .pan_details {
    font-size: var(--subtitle-font-size);
    color: var(--title-color);
    font-weight: 600;
}

.page_wrapper .repay_loan_section .pan_number .agreement {
    margin-top: 20px;
    display: flex;
    align-items: center;
    min-width: 300px;
    width: 50%;
}



.page_wrapper .repay_loan_section .pan_number .agreement span {
    color: var(--title-color);
}

.page_wrapper .repay_loan_section .pan_number .agreement input[type="checkbox"] {
    min-width: 15px;
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.page_wrapper .bank_details_section {
    width: 80%;
    margin: 80px auto 0px auto;
    gap: 10%;
}

.page_wrapper .bank_details_section .details_table tr {
    padding: 10px 20px;
    border-top: 2px solid #cccccc;
}

.page_wrapper .bank_details_section .details_table tr .account_field_value {
    color: var(--body-color);
    font-size: var(--para-font-size);
    font-weight: 600;
}

.page_wrapper .bank_details_section .details_table tr .account_data_value {
    font-size: var(--para-font-size);
}

.page_wrapper .bank_details_section .qr_details img {
    max-width: 350px;
}


/* ************************ ABOUT US PAGE CSS *************************  */
.company_info_section {
    width: 100%;
    padding: 40px;
}

.company_info_section .company_info_wrapper {
    width: 98%;
    margin: 30px auto;
    padding: 40px;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    transition: All 600ms ease;
    -webkit-transition: All 600ms ease;
}

.company_info_section .company_info_wrapper .company_info_content {
    font-size: 18px;
    line-height: 40px;
    max-width: 80%;
    min-width: 300px;
    margin: 20px auto 20px auto;
    color: var(--black-color2);
}

.company_info_section .company_info_wrapper .button_container {
    width: 80%;
    margin: 50px auto 0px auto;
    display: flex;
    justify-content: space-evenly;
}

.about_us_section_1 {
    width: 100%;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about_us_section_1 .about_us_section_1_right {
    max-width: 50%;
}

.about_us_section_1 .about_us_section_1_right p {
    font-family: var(--body-font);
    line-height: 1.78;
    color: var(--body-color);
    max-width: 95%;
    font-size: var(--para-font-size);
}

.about_us_section_1 .about_us_section_1_right .about_features_section {
    display: flex;
    justify-content: space-between;
    max-width: 95%;
    align-items: center;
    border-bottom: 2px solid var(--th-border-color);
    padding: 30px 20px 20px 20px;
}


.about_us_section_1 .about_us_section_1_right .about_features_section h3 {
    color: var(--title-color);
}

.about_us_section_1 .about_us_section_1_right .about_features_section p {
    font-size: var(--links-font-size);
    margin-top: 5px;
}

.about_us_section_1 .about_us_section_1_2_left {
    position: relative;
    z-index: 2;
}

.about_us_section_1 .about_us_section_1_2_left img {
    -webkit-animation: morph 10s ease-in-out infinite;
    animation: morph 10s ease-in-out infinite;
    width: 100%;
    max-width: 760px;
}

.about_us_section_1 .about_us_section_1_2_left::before {
    content: '';
    position: absolute;
    background-color: var(--theme-color);
    width: 100%;
    height: 100%;
    -webkit-animation: morph 10s ease-in-out infinite;
    animation: morph 10s ease-in-out infinite;
    z-index: -1;
    top: -20px;
    left: -40px;
    opacity: 0.5;
    -webkit-animation-delay: 0.2;
    animation-delay: 0.2;
}

.about_us_section_1 .about_us_section_1_2_left::after {
    content: '';
    right: -20px;
    bottom: -40px;
    background-color: var(--smoke-color2);
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
    z-index: -2;
    width: 100%;
    height: 100%;
    -webkit-animation: morph 10s ease-in-out infinite;
    animation: morph 10s ease-in-out infinite;
}

@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}


.about_us_section_1 .about_us_section_1_right .about_features_section_2 {
    max-width: 70%;
}

.about_us_section_1 .about_us_section_1_right .about_features_section_2 h3 {
    color: var(--title-color);
    font-size: var(--para-font-size);
}


.about_us_section_1 .about_us_section_1_right .about_features_section_2 .about_features_item {
    min-width: 240px;
}

.about_us_section_1 .about_us_section_1_right .about_features_section_2 .about_features_item .check_icon {
    font-size: var(--icon-font-size);
    border-radius: 50%;
    border: 1px dashed var(--theme-color);
    padding: 9px;
    color: var(--theme-color);
}

/* ************************** Contact Page CSS ******************************  */
.contact_page_details {
    width: 100%;
    padding: 110px 40px;
}

.contact_page_details .contact_page_details_row {
    width: 90%;
    margin: 0 auto;
    gap: 30px;
    padding: 0px 0px 80px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact_page_details .contact_page_details_row .contact_page_details_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; */
    gap: 25px;
    box-shadow: 0px 4px 30px rgba(8, 14, 28, 0.08);
    border-radius: 5px;
    background-color: var(--white-color);
    padding: 40px;
}

.contact_page_details .contact_page_details_row .contact_page_details_item .contact_page_details_item_icon {
    padding: 25px;
    background: var(--theme-color);
    color: var(--white-color);
    border-radius: 6px;
}

.contact_page_details .contact_page_details_row .contact_page_details_item .contact_page_details_item_icon .contact_icon {
    font-size: var(--icon-font-size);
}

.contact_page_details .contact_page_details_row .contact_page_details_item .contact_page_details_item_text h3 {
    color: var(--title-color);
    font-size: var(--subtitle-font-size);
    font-weight: 700;
    line-height: 1.417;
}

.contact_page_details .contact_form_container {
    width: 100%;
    background: url('../images/contact_form_img.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 80px 40px;
}

.contact_page_details .contact_form_container .contact_form {
    max-width: 80%;
}

.contact_page_details .contact_form_container .contact_form .row {
    width: 100%;
    display: flex;
    align-items: center;
}

.contact_page_details .contact_form_container .contact_form .row .input_item {
    width: 100%;
    max-width: 400px;
}

.contact_page_details .contact_form_container .contact_form .row .input_item input {
    height: 56px;
    padding: 0 25px 0 25px;
    padding-right: 45px;
    border: 1px solid #e1e0e0;
    color: var(--body-color);
    background-color: var(--smoke-color);
    border-radius: 4px;
    font-size: var(--input-font-size);
    width: 100%;
    max-width: 350px;
    font-family: var(--body-font);
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
}


.contact_page_details .contact_form_container .contact_form .row .input_item input:focus {
    border: 1px solid var(--theme-color);
    outline: none;
}

.contact_page_details .contact_form_container .contact_form .row textarea {
    padding: 10px 25px 10px 25px;
    padding-right: 45px;
    border: 1px solid #dddada;
    color: var(--body-color);
    background-color: var(--smoke-color);
    border-radius: 4px;
    font-size: var(--input-font-size);
    font-family: var(--body-font);
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
}

.contact_page_details .contact_form_container .contact_form .row textarea:focus {
    border: 1px solid var(--theme-color);
    outline: none;
}

.contact_page_details .contact_form_container .contact_form .btn_container {
    width: 40%;
    margin: 40px auto;
}

.contact_page_details .google_map_container {
    width: 100%;
    min-height: 600px;
}

.contact_page_details .google_map_container iframe {
    width: 100%;
    border: none;
    min-height: 600px;
}

/* **************************   services page section  ********************************  */
/* .services_page_section {
    width: 100%;
    background: var(--white-color);
    position: relative;
    padding: 40px;
}

.services_page_section .services_row {
    width: 90%;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 40px;
}

.services_page_section .services_row a {
    color: var(--title-color);
}

.services_page_section .services_row .services_card {
    padding: 30px;
    position: relative;
    z-index: 2;
    box-shadow: 0px 0px 18px 0px rgba(51, 60, 142, 0.15);
    background-color: var(--white-color);
    border-radius: 10px;
    overflow: hidden;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    max-width: 300px;
    min-height: 357px;
}

.services_page_section .services_row .services_card:hover {
    cursor: pointer;
}


.services_page_section .services_row .services_card .service_icon {
    font-size: var(--icon-font-size);
    color: var(--theme-color);
}

.services_page_section .services_row .services_card::before,
.services_card::after {
    content: '';
    position: absolute;
    height: 110px;
    width: 110px;
    background-color: var(--theme-color);
    opacity: 0.6;
    border-radius: 50%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    z-index: -1
}

.services_page_section .services_row .services_card::before {
    bottom: -73px;
    right: -28px
}

.services_page_section .services_row .services_card::after {
    right: -73px;
    bottom: -28px
}

.services_page_section .services_row .services_card .shape_icon {
    position: relative;
    z-index: 2;
    width: 90px;
    height: 85px;
    line-height: 85px;
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services_page_section .services_row .services_card .shape_icon::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--smoke-color2);
    -webkit-clip-path: path("M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z");
    clip-path: path("M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z");
    z-index: -1;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
}

.services_page_section .services_row .services_card:hover .shape_icon::before {
    background: white;
}

.services_page_section .services_row .services_card .shape_icon .dots::before {
    content: '';
    position: absolute;
    background-color: var(--theme-color);
    height: 24px;
    width: 23px;
    border-radius: 50%;
    top: 0;
    right: 9px;
}

.services_page_section .services_row .services_card .shape_icon .dots::after {
    height: 12px;
    width: 11px;
    bottom: 0;
    left: 27px;
    content: '';
    position: absolute;
    background-color: var(--theme-color);
    border-radius: 50%;
}

.services_page_section .services_row .services_card .bg_shape {
    position: absolute;
    bottom: -200px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    pointer-events: none
}

.services_page_section .services_row .services_card .bg_shape img {
    width: 100
}

.services_page_section .services_row .services_card .service_card_number {
    position: absolute;
    top: 25px;
    right: 40px;
    font-size: 5rem;
    line-height: 1;
    font-weight: bold;
    color: var(--smoke-color2);
    opacity: 0.3
}

.services_page_section .services_row .services_card .service_card_number .service_card_text {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    margin-bottom: 22px
}

.services_page_section .services_row .services_card:hover::before {
    opacity: 1;
    height: 130%;
    width: 120%;
    border-radius: 0
}

.services_page_section .services_row .services_card:hover::after {
    opacity: 1;
    height: 120%;
    width: 120%;
    border-radius: 0
}

.services_page_section .services_row .services_card:hover .bg_shape {
    bottom: 0;
    opacity: 1
}

.services_page_section .services_row .services_card:hover .box_title {
    color: white;
}

.services_page_section .services_row .services_card:hover .service_card_text {
    color: white;
}

.services_page_section .services_row .services_card:hover .btn_container a {
    background: white;
    border: 1px solid white;
    color: var(--theme-color);
}  */

.services_page_section {
    width: 100%;
    background: var(--white-color);
    padding: 40px;
}

.services_page_section .services_row {
    width: 100%;
    margin: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 40px;
}

.services_page_section .services_row .services_item {
    padding: 30px;
    box-shadow: 0px 0px 18px 0px rgba(51, 60, 142, 0.15);
    background-color: var(--white-color);
    border-radius: 10px;
    overflow: hidden;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    max-width: 300px;
    min-height: 320px;
}

.services_page_section .services_row .services_item .services_icon_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.services_page_section .services_row .services_item .services_icon_container .services_icon {
    color: var(--theme-color);
    font-size: var(--icon-font-size)
}

.services_page_section .services_row .services_item .services_content {
    text-align: center;
}

.services_page_section .services_row .services_item .services_content .services_title {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.services_page_section .services_row .services_item .services_content .services_title h4 {
    color: var(--title-color);
    font-family: var(--title-font);
    font-size: 1.5rem;
    font-size: var(--service-title-font-size);
    font-weight: 700;
    line-height: 1.417;
}

.services_page_section .services_row .services_item .services_content p {
    font-family: var(--body-font);
    color: var(--body-color);
    line-height: 1.75;
}

.services_page_section .services_row .services_item:hover {
    color: white;
    background: linear-gradient(-38deg, #26b9db 0%, #26b9db 150%, #26b9db 100%);
    opacity: 1;
    transition: all ease 400ms;
    -webkit-transition: all ease 400ms;
    cursor: pointer;
    transform: scale(1.04);
}

.services_page_section .services_row .services_item:hover .services_icon_container .services_icon {
    color: white;
}

.services_page_section .services_row .services_item:hover .services_content .services_title h4 {
    color: white;
}

.services_page_section .services_row .services_item:hover .services_content p {
    color: white;
}

/* ******Multi step form **************************  */


/* Multi Step form  */
.multi_step_form_container {
    width: 98%;
    margin: 20px auto;
}

.multi_step_form_container .form_title {
    padding: 10px 30px;
    width: 100%;
    text-align: center;
}

.multi_step_form_container .form_title h2 {
    color: var(--title-color);
    font-size: var(--title-font-size);
    font-family: var(--body-font);
}

.multi_step_form_container .form_title p {
    margin-top: 5px;
    color: var(--title-color);
}

.multi_step_form_container .form_container {
    width: 95%;
    margin: 40px auto;
}

.multi_step_form_container .form_container .css-u4p24i {
    flex-wrap: wrap !important;
    gap: 10px 0px;
}

.multi_step_form_container .form_container .row {
    width: 100%;
}

.multi_step_form_container .form_container .personal_details_row {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.multi_step_form_container .form_container p {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    color: var(--title-color);
}

.multi_step_form_container .form_container .row .form_item {
    min-width: 300px;
}

.multi_step_form_container .form_container .row .form_item label {
    color: var(--title-color);
    display: block;
    font-size: 0.92rem;
    min-width: fit-content;
}

.multi_step_form_container .form_container .row .form_item input::file-selector-button {
    color: whitesmoke;
    padding: 6px 12px;
    background: var(--theme-color);
    border: 1px solid var(--theme-color);
    border-radius: 3px;
    cursor: pointer;
}

.multi_step_form_container .form_container .row .form_item select {
    padding: 8px 0px;
    border: none;
    border-bottom: 1px solid #cccccc;
    /* min-width: 240px; */
    font-size: var(--input-font-size);
    width: 100%;

    &:focus {
        border-bottom: 1px solid var(--theme-color);
        outline: none;
    }
}

.multi_step_form_container .form_container .row .form_item .radio_label {
    font-size: 14px;
}

.multi_step_form_container .form_container .row .form_item input {
    padding: 8px 12px;
    border: none;
    border-bottom: 1px solid #cccccc;
    font-size: var(--input-font-size);

    &::placeholder {
        color: var(--title-color);
    }

    &:focus {
        border-bottom: 1px solid var(--theme-color);
        outline: none;
    }
}

.multi_step_form_container .form_container .row .form_item input[type="radio"] {
    height: 18px;
}

.multi_step_form_container .form_container .row .mobile_number {
    width: 60%;
    margin: 40px auto;
}

.multi_step_form_container .form_container .agreement {
    width: 60%;
    margin: 30px auto 3px auto;
    display: flex;
    align-items: center;
}

.multi_step_form_container .form_container .checkbox_error_container {
    width: 60%;
    margin: 0px auto 10px auto;
    display: flex;
    align-items: center;
}

.multi_step_form_container .form_container .agreement input {
    margin-top: -5px;
    width: 17px;
    height: 17px;
}

.multi_step_form_container .form_container .agreement p {
    color: var(--title-color);
    font-size: var(--label-font-size);
    width: 80%;
    text-align: left;
    margin-left: 10px;
    line-height: 1.6;
}

.multi_step_form_container .form_container .row .mobile_number input {
    width: 100%;
}

.multi_step_form_container .form_container .form_button_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.multi_step_form_container .form_container .form_button_container button {
    background: var(--theme-color);
    border: 1px solid var(--theme-color);
    color: white;
    font-size: var(--label-font-size);
    padding: 6px 12px;
}


.multi_step_form_container .form_container .form_button_container button:disabled {
    background: #adadad;
    border: 1px solid #adadad;
}

.multi_step_form_container .form_container .border_row {
    padding: 15px 20px;
    border-radius: 6px;
    border: 2px solid #cccccc;
}


.multi_step_form_container .form_container .border_row input {
    width: 18px;
    height: 18px;
}

.multi_step_form_container .form_container .border_row p {
    max-width: 500px;
    text-align: left;
    margin-bottom: 0px;
    margin-left: 10px;
}

.multi_step_form_container .form_container .form_row {
    width: 100%;
}

.multi_step_form_container .form_container .row .form_item {
    width: 60%;
    margin: 20px auto;
}

.multi_step_form_container .form_container .title {
    margin-top: 30px;
    text-align: center;
    width: 100%;
}

.multi_step_form_container .form_container .title h2 {
    color: var(--title-color);
    font-family: var(--body-font);
    ;
}

.multi_step_form_container .form_button_container .thankyou_message p {
    color: var(--title-color);
    text-align: center;
    max-width: 50%;
    margin: 0px auto;
}

.multi_step_form_container .form_container .individual_details_row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    margin-top: 30px;
}

.multi_step_form_container .form_container .individual_details_row:nth-child(1) {
    margin-top: 0px;
}

.multi_step_form_container .form_container .individual_details_row .individual_details_item {
    min-width: 200px;
    position: relative;
}

.multi_step_form_container .form_container .individual_details_row .individual_details_item input {
    padding: 8px 12px;
    border: none;
    border-bottom: 1px solid #cccccc;
    font-size: var(--input-font-size);
    min-width: 218px;

    &::placeholder {
        color: var(--title-color);
    }

    &:focus {
        border-bottom: 1px solid var(--theme-color);
        outline: none;
    }
}

.multi_step_form_container .form_container .individual_details_row .individual_details_item select {
    padding: 8px 12px;
    border: none;
    border-bottom: 1px solid #cccccc;
    font-size: var(--input-font-size);
    min-width: 218px;
    max-width: 218px;
    background: transparent;

    &::placeholder {
        color: var(--title-color);
    }

    &:focus {
        border-bottom: 1px solid var(--theme-color);
        outline: none;
    }
}

.multi_step_form_container .form_container .individual_details_row .individual_details_item label {
    color: var(--title-color);
    display: block;
    font-size: 0.92rem;
    min-width: fit-content;
}

.multi_step_form_container .form_container .otp_resend {
    margin-top: 10px;
}

.multi_step_form_container .form_container .otp_resend button {
    color: var(--theme-color);
    background: transparent;
    text-decoration: underline;
    border: none;
}

.multi_step_form_container .form_container .otp_resend .otp_resent_alert {
    margin: 6px 0px;
}

.multi_step_form_container .form_container .otp_resend .otp_resent_alert span {
    color: red;
    font-weight: 600;
}

.multi_step_form_container .form_container .otp_resend button:disabled {
    color: #adadad;
    background: transparent;

}

.hidden {
    display: none !important;
}

/**************************** Loan Calculator CSS ************************************/
.loan_calculator{
    /* border: 4px solid var(--theme-color); */
    padding: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    background-image: url('../images/Grow_background.png');
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1) !important;
}

.loan_calculator h2 {
    color: var(--title-color);
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}

.loan_calculator .input_item {
    margin-bottom: 10px;
}

.loan_calculator .input_item input {
    min-width: 250px;
    background: var(--th-border-color);
    width: calc(100% -(78px));
    /* height: 12px; */
    padding: 4px;
    border-radius: 4px;
    /* box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.4); */
    /* outline: none; */
    -webkit-appearance: none;
    position: relative;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--theme-color);
}


.loan_calculator .illustrative_values {
    max-width: 290px;
}

.loan_calculator .input_item .data_values {
    color: #fff;
    background: var(--theme-color);
    font-size: var(--label-font-size);
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    width: 70px;
    height: 30px;
    display: inline-block;
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.rupee_icon {
    margin-bottom: -2px;
}

.loan_amount_display_container p {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat_icon_link {
    position: fixed;
    bottom: 55px;
    right: 10px;
    z-index: 10;
}

.chat_icon_link .chat_icon_container {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: var(--theme-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat_icon_link .chat_icon_container .chat_icon {
    font-size: 30px;
}

.chat_icon_link .chat_icon_container .call_icon {
    font-size: 26px;
}

/* RESPONSIVE CSS  */

@media screen and (min-width: 1950px) {

    .page_banner_wrapper {
        min-height: fit-content;
    }

    .page_banner_wrapper .repay_loan_banner_overlay {
        min-height: fit-content;
    }

    .page_banner_wrapper .page_banner_wrapper_overlay {
        min-height: fit-content;
    }

    .footer_wrapper {
        min-height: fit-content;
    }

    .general_working {
        min-height: fit-content;
    }

    .features_section {
        min-height: fit-content;
    }

    .services_section {
        min-height: 400px;
    }
}

/* navbar responsiveness  */
@media screen and (max-width: 1200px) {
    .header_wrapper nav {
        width: 100%;
        /* z-index: 0; */
    }

    /* .features_section {
        padding-top: 250px;
    } */

    .features_section .features_section_content .features_image img {
        max-width: 400px;
    }

    .general_working .process_card_area .process_line {
        display: none;
    }

    .general_working .process_card_area .process_row {
        gap: 80px 30px;
    }

    .about_us_section_1 {
        padding: 40px 20px;
    }

    /* multistep form responsiveness  */
    .multi_step_form_container .form_container .css-m5vj9m-MuiStepper-root {
        flex-wrap: wrap;
        gap: 10px 0px;
    }

    .multi_step_form_container .form_container .form_button_container button {
        padding: 4px 10px;
    }

    /* repay loan page responsiveness  */
    .page_wrapper .bank_details_section {
        width: 98%;
    }

    .advantages_section .advantages_section_row .advantage_section_card {
        max-width: 300px;
    }

    .advantages_section .advantages_section_row .advantage_section_card .advantage_section_icon_container {
        width: 50px;
        height: 50px;
    }
}

@media screen and (max-width: 1000px) {
    :root {
        --title-font-size: 1.63rem;
        --banner-font-size: 2.2rem;
    }

    .top_bar_wrapper .top_bar_container {
        width: 100%;
        margin: 0px;
    }

    .services_section .services_card_container .services_card {
        min-width: 260px;
        padding: 16px;
    }

    .footer_wrapper .footer_row {
        flex-wrap: wrap;
        gap: 10px 0px;
    }

    .footer_wrapper .footer_row .footer_tab {
        margin-left: 0px;
        min-width: 300px;
    }

    /* about us page responsiveness */
    .about_us_section_1 {
        padding: 30px 10px;
    }

    .about_us_section_1 .about_us_section_1_left img {
        max-width: 400px;
    }

    .about_us_section_1 .about_us_section_1_right .about_features_section {
        max-width: 105%;
        padding: 30px 10px 20px 10px;
    }

    .about_us_section_1 .about_us_section_1_2_left img {
        max-width: 400px;
    }

    /* contact page responsiveness  */
    .contact_page_details .contact_page_details_row .contact_page_details_item {
        padding: 10px;
    }

    .footer_dropdown_container .submenu .submenu_list {
        flex-wrap: wrap;
        gap: 0px;
    }

    .get_started_section .get_started_row {
        width: 98%;
    }

    .advantages_section .advantages_section_row {
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 900px) {
    :root {
        --label-font-size: 0.85rem;
        --logo-font-size: 1.4rem;
    }

    .header_wrapper {
        flex-direction: column;
        justify-content: center;
        transition: all .7s ease-out;
        height: 470px;
        /* z-index: 0; */
    }

    .header_wrapper nav .burger {
        display: block;
    }

    .header_wrapper nav .logo_wrapper img {
        max-width: 90px;
        margin-left: 15px;
    }

    .header_wrapper nav .logo_wrapper p {
        margin-left: 0px;
        margin-bottom: 10px;
    }

    .header_wrapper nav {
        flex-direction: column;
        justify-content: center;
        padding-bottom: 20px;
    }

    .header_wrapper nav .nav_list {
        flex-direction: column;
        align-items: center;
        transition: all .7s ease-out;
        gap: 25px;
    }

    .h-nav {
        height: 100px;
    }

    .v-class {
        opacity: 0;
        visibility: hidden;
    }

    .header_wrapper .nav_list li .apply_now_button_link {
        margin-left: 0px;
    }

    .services_section .services_card_container {
        flex-wrap: wrap;
        gap: 100px 50px;
        width: 100%;
    }

    /* .features_section {
        padding-top: 650px;
    } */

    .features_section .features_section_content {
        padding: 0px 15px;
    }

    .number_statistics .number_row {
        padding: 50px 10px;
        flex-wrap: wrap;
        gap: 30px 0px;
    }

    /* about us page responsiveness  */
    .about_us_section_1 {
        flex-direction: column;
    }

    .about_us_section_1 .about_us_section_1_left img {
        margin-bottom: 30px;
    }

    .about_us_section_1 .about_us_section_1_right {
        max-width: 100%;
    }

    .about_us_section_1 .about_us_section_1_2_left {
        margin-top: 50px;
    }

    /* multipstepform responsiveness  */
    .multi_step_form_container .form_container .agreement {
        width: 90%;
    }

    .multi_step_form_container .form_container .agreement p {
        width: 100%;
    }

    .general_working {
        padding: 40px 10px 50px 10px;
    }

}

@media screen and (max-width: 820px) {

    .banner_wrapper .overlay .overlay_text {
        margin-top: 50px;
    }

    .loan_calculator .input_item input {
        min-width: 200px;
    }

    .services_section .title_section {
        max-width: 80%;
    }

    .features_section .features_section_content {
        flex-direction: column;
    }

    .features_section .features_section_content .features_section_content_description p {
        max-width: 100%;
    }

    /* contact page responsiveness  */
    .contact_page_details .contact_page_details_row .contact_page_details_item .contact_page_details_item_icon {
        padding: 10px;
    }

    /* multistep  form responsiveness  */
    .multi_step_form_container .form_container .personal_details_row {
        flex-direction: column;
        gap: 10px;
    }

    .page_wrapper .bank_details_section {
        width: 98%;
        flex-direction: column;
    }

    .page_wrapper .bank_details_section .qr_details {
        margin-top: 50px;
    }

    .get_started_section .get_started_row .image_section img {
        max-height: 300px;
    }

    .get_started_section .get_started_row .image_section .overlay_img {
        max-width: 120px;
    }

    .get_started_section .get_started_row .steps_section {
        margin-top: 0px;
    }

    .get_started_section .get_started_row {
        align-items: center;
    }

    .control_btn {
        padding: 6px;
        font-size: 16px; /* Adjust based on your design */
      }
}

@media screen and (max-width: 780px) {
    .top_bar_wrapper .top_bar_container {
        width: 100%;
        margin: 0px;
        flex-direction: column;
    }

    .top_bar_wrapper .top_bar_container .mail {
        margin-top: 5px;
    }


    .banner_wrapper .overlay .overlay_text {
        margin-left: 10px;
    }

    .banner_wrapper .overlay .overlay_text .tagline {
        width: 100%;
    }

    .banner_wrapper .overlay .overlay_text .features_tagline {
        width: 90%;
        margin-left: 0px;
        text-wrap: nowrap;
    }

    .about_us {
        padding: 40px 20px;
    }

    /* contact page responsivness  */
    .contact_page_details .contact_page_details_row .contact_page_details_item {
        min-height: 200px;
        min-width: 170px;
    }
}

/* @media screen and (max-width: 740px) {
    .features_section {
        padding-top: 680px;
    }
} */

@media screen and (max-width:660px) {
    .services_section .services_card_container {
        top: 58%;
    }

    /* .features_section {
        padding-top: 1100px;
    } */

    /* contact page css */
    .contact_page_details .contact_page_details_row {
        flex-wrap: wrap;
    }

    .contact_page_details .contact_page_details_row .contact_page_details_item {
        min-width: 300px;
        min-height: 100px;
    }

    .get_started_section .get_started_row {
        flex-direction: column;
    }

    .get_started_section .get_started_row .steps_section {
        margin-top: 20px;
    }

    .get_started_section .get_started_row .steps_section h3 {
        width: 100%;
        text-align: center;
    }

    .get_started_section .get_started_row .image_section img {
        max-height: 400px;
    }

    .get_started_section .get_started_row .image_section .overlay_img {
        max-width: 160px;
    }

}

@media screen and (max-width: 680px) {

    .banner_wrapper .overlay .overlay_text .white_line {
        width: 60%;
        min-width: 262px;
    }

    .banner_wrapper .overlay .overlay_text .tagline {
        max-width: 80%;
    }

    .banner_wrapper .overlay .overlay_text .features_tagline p {
        margin-left: 5px;
    }

    .banner_wrapper .overlay .overlay_text .features_tagline p:nth-child(1) {
        margin-left: 0px;
    }

    .banner_wrapper .overlay .overlay_text .info_buttons a {
        padding: 6px 10px;
    }

    .about_us {
        flex-direction: column;
        justify-content: flex-start;
    }

    .about_us .about_us_content {
        width: 98%;
        margin-bottom: 30px;
    }

    .loan_calculator .input_item input {
        min-width: 250px;
    }

    /* multistep form responsiveness  */
}

@media screen and (max-width: 610px) {
    :root {
        --title-font-size: 1.45rem;
        --service-title-font-size: 1.35rem;
        --subtitle-font-size: 1.18rem;
        --icon-font-size: 2.3rem;
    }

    .top_bar_wrapper .top_bar_container .address_number {
        flex-direction: column;
        justify-content: center;
    }

    .top_bar_wrapper .top_bar_container .address_number .number {
        margin-left: 0px;
    }



    .services_section .title_section {
        max-width: 70%;
    }

    .services_section .title_section h2 {
        width: 100%;
    }

    .number_statistics .number_row .number_statistics_item .number_statistics_icon img {
        width: 40px;
    }

    .contact_page_details {
        padding: 50px 10px;
    }

    .contact_page_details .contact_form_container {
        padding: 60px 10px;
    }

    .contact_page_details .contact_form_container .contact_form {
        max-width: 100%;
    }

    /* contact form responsiveness  */
    .contact_page_details .contact_form_container .contact_form .row .input_item input {
        min-width: 170px;
    }

    .company_info_section .company_info_wrapper {
        padding: 20px 10px;
    }

    .company_info_section .company_info_wrapper .company_info_content {
        max-width: 100%;
    }

    .company_info_section .company_info_wrapper .company_info_content .company_info_section .company_info_wrapper .button_container {
        width: 100%
    }

    .advantages_section .advantages_section_row .advantage_section_card {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 98%;
        gap: 30px;
        padding: 10px;
    }
}

@media screen and (min-width:520px) {
    .md_none {
        display: none !important;
    }
}

@media screen and (max-width: 520px) {
    .services_section {
        padding: 30px 0px;
    }

    .services_section .info_content {
        width: 100%;
    }

    .multi_step_form_container .form_container .individual_details_row {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 0px;
    }

    .multi_step_form_container .form_container .individual_details_row .individual_details_item {
        margin-top: 30px;
    }


    /* about us page responsiveness  */
    .about_us_section_1 .about_us_section_1_right .about_features_section {
        flex-direction: column;
        gap: 30px 0px;
    }

    .about_us_section_1 .about_us_section_1_2_left {
        max-width: 300px;
    }

    .footer_wrapper .copyright_section p {
        font-size: 14px;
    }

    .x_sm_none {
        display: none !important;
    }


    .footer_dropdown_container .footer_dropdown {
        flex-direction: column;
        gap: 20px;
    }

    .footer_dropdown_container .footer_dropdown .dropdown_item_title .dropdown_title {
        max-width: 100%;
        width: 100%;
        justify-content: center;
    }

    .get_started_section .get_started_row .steps_section .step_item .step_content {
        padding: 10px 12px;
    }

    .get_started_section .get_started_row .steps_section .step_item .step_content .content_title {
        font-size: 20px;
    }

    .get_started_section {
        padding: 30px 10px;
    }

    .get_started_section .get_started_row .steps_section .step_item {
        flex-direction: column;
        align-items: center;
    }

    .get_started_section .get_started_row .steps_section .step_item .step_icon_container {
        margin-bottom: 20px;
    }

    .get_started_section .get_started_row .steps_section .step_item:not(.last_item):before {
        display: none !important;
    }

    .get_started_section .get_started_row .steps_section .app_download_button_container {
        margin-left: 0px !important;
    }

    .get_started_section .get_started_row .steps_section .app_download_button_container p {
        width: 100%;
        text-align: center;
    }

    .get_started_section .get_started_row .steps_section .app_download_button_container .button_wrapper {
        justify-content: center;
        width: 100%;
    }


    .company_info_section .company_info_wrapper .button_container {
        flex-direction: column;
    }

    .company_info_section .company_info_wrapper .button_container button {
        margin-top: 20px;
        margin-right: 0px;
    }

    .advantages_section .advantages_section_row .advantage_section_card .advantage_section_icon_container .advantage_icon {
        font-size: 20px;
    }

    .advantages_section .advantages_section_row .advantage_section_card .advantage_section_icon_container {
        height: 90px;
        width: 90px;
        padding: 10px;
    }

    .advantages_section:before {
        border-radius: 4px;
    }

    
    .control_btn {
        padding: 4px;
        font-size: 14px; /* Adjust based on your design */
      }

}

@media screen and (max-width: 450px) {
    :root {
        --para-font-size: 0.9rem
    }

    .top_bar_wrapper {
        padding: 7px 0px;
    }

    .top_bar_wrapper .top_bar_container {
        padding: 5px 2px;
    }

    .banner_wrapper .overlay .overlay_text .tagline {
        max-width: 90%;
    }

    /* about us responsiveness  */
    .about_us_section_1 .about_us_section_1_left img {
        max-width: 300px;
    }

    .about_us_section_1 .about_us_section_1_right .about_features_section_2 {
        max-width: 50%;
    }

    .about_us_section_1 .about_us_section_1_right .about_features_section_2 .about_features_item {
        min-width: 180px;
    }

    /* services page responsiveness  */
    .services_page_section {
        padding: 20px 0px;
    }

    /* contact page responsiveness  */
    .contact_page_details .contact_form_container .contact_form .row .input_item {
        margin-right: 10px;
    }

    .contact_page_details .contact_form_container .contact_form .btn_container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .footer_wrapper .copyright_section p {
        text-align: center;
        font-size: 13px;
    }

    .services_section .title_section {
        max-width: 100%;
    }

    .xs_sc_none {
        display: none;
    }

    .services_section .title_section h2 {
        max-width: 100%;
    }

    .page_wrapper .repay_loan_section {
        width: 100%;
    }

    .page_wrapper .repay_loan_section .pan_number .agreement span {
        font-size: 14px;
    }

    .hide_content {
        display: none;
    }

    .company_info_section {
        padding: 20px 10px;
    }

    .company_info_section .company_info_wrapper {
        width: 100%;
    }

    .company_info_section .company_info_wrapper .company_info_content {
        min-width: 270px;
        line-height: 24px;
    }

    .company_info_section .company_info_wrapper .button_container {
        margin-top: 20px;
    }
}

@media screen and (max-width: 370px) {
    :root {
        --banner-font-size: 1.8rem;
    }

    .banner_wrapper .overlay .overlay_text .features_tagline {
        width: 100%;
    }

    .banner_wrapper .overlay .overlay_text .features_tagline p {
        margin-left: 1px;
    }

    .x_sm_none {
        display: none !important;
    }

    .features_section .features_section_content .features_image img {
        margin-top: 10px;
        max-width: 100%;
    }

    .loan_calculator {
        max-width: 300px;
    }

    /* contact form responsiveness  */
    .contact_page_details .contact_form_container .contact_form .row {
        flex-direction: column;
        margin-top: 0px;
        justify-content: center;
    }

    .contact_page_details .contact_form_container .contact_form .row .input_item {
        margin-top: 20px;
        width: 100%;
    }

    .contact_page_details .contact_form_container .contact_form .row textarea {
        margin-top: 20px;
    }

    .services_section .services_card_container {
        top: 70%;
    }

    .services_section .services_card_container .services_card {
        min-width: 200px;
    }

    .page_wrapper .bank_details_section .qr_details img {
        max-width: 310px;
    }
}

@media screen and (max-width: 330px) {
    .services_section .services_card_container {
        top: 75%;
    }

    .features_section {
        padding-top: 1150px;
    }

    .page_wrapper .bank_details_section .qr_details img {
        max-width: 270px;
    }
}


/* HTML: <div className="loader"></div> */
.loadinganim {
    width: 25px;
    padding: 5px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #ffffff;
    --_m:
        conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    to {
        transform: rotate(1turn)
    }
}




.loader-container {
    padding-top: 5%;
    display: flex;
    flex-direction: row;
}

.loader-wrapper {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.loader-item {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
    font-size: 30px;
    border: 4px solid #4361BA;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 1s loader-animation linear infinite;
    position: relative;
}

.loader-item i {
    font-size: 25px;
}

@keyframes loader-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-success-completed {
    animation: none;
    border-bottom-color: none;
    border-color: #00b359;
    border-width: 3px;
    animation: fade-in-bck 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.loader-failure-completed {
    animation: none;
    border-bottom-color: none;
    border-color: #ff6666;
    border-width: 3px;
    animation: fade-in-bck 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.loader-check-icon {
    display: none;
}

.loader-check-icon-completed {
    font-size: 53px;
    display: block;
    color: #00b359;
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.loader-failure-icon {
    display: none;
    /* font-size: 30px; */
}

.loader-failure-icon-completed {
    font-size: 53px;
    display: block;
    color: #ff6666;
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fade-in-bck {
    0% {
        -webkit-transform: translateZ(80px);
        transform: translateZ(80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

#snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
}

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}


/* Common.css */

.thank-you-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    margin: 0;
  }
  
  .thank-you-content {
    text-align: center;
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .thank-you-image-container {
    margin-bottom: 20px;
  }
  
  .thank-you-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .thank-you-message h2 {
    font-size: 24px;
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  .thank-you-message p {
    font-size: 16px;
    color: #666;
  }
  
  .redirect-info p {
    font-size: 14px;
    color: #999;
  }
  
  .thank-you-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #8cd9a2;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .thank-you-button:hover {
    background-color: #79c48d;
  }
  



  /* .message-banner {
    background-color: #797575;
    color: #fff;
    padding: 15px;
    text-align: center;
    margin-bottom: 20px;
    width: 30%;
    margin-top: 10px;
    margin-left: 10px;
    transition: opacity 0.1s ease;
} */
